<template>
  <div style="padding: 0 3%">
    <el-form :model="form" ref="form" :inline="true" style="margin-top: 1%; width: 70%;" center>

      <el-divider content-position="center">公司资质情况</el-divider>
      <table class="myTable" cellspacing="0px">
        <tr>
          <td>公司名称：</td>
          <td colspan="3">
            <el-input v-model="form.unitName"></el-input>
          </td>
        </tr>
        <tr>
          <td>公司邮箱：</td>
          <td colspan="3">
            <el-input v-model="form.unitEmail"></el-input>
          </td>
        </tr>
        <tr>
          <td>公司地址：</td>
          <td colspan="3">
            <el-input v-model="form.unitAddress"></el-input>
          </td>
        </tr>
        <tr>
          <td>营业执照注册号：</td>
          <td colspan="3">
            <el-input v-model="form.dutyNumber"></el-input>
          </td>
        </tr>
        <tr>
          <td>公司成立日期：</td>
          <td>
            <el-date-picker
                v-model="form.unitRegisterDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
            </el-date-picker>
          </td>
          <td>公司人员：</td>
          <td>
            <el-input v-model="form.unitScale"></el-input>
          </td>
        </tr>

        <tr>
          <td>营业执照副本复印件：</td>
          <td colspan="2">
            <el-upload v-model="form.businessLicense"
                       class="upload-demo"
                       action=""
                       :http-request="handlePreview1"
                       :on-preview="handlePictureCardPreview"
                       :on-remove="handleRemove1"
                       :file-list="fileList"
                       :limit="1"
                       :show-file-list="true"
                       accept="image/*"
                       list-type="picture-card">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog title="查看" :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt=""/>
            </el-dialog>
          </td>
          <td style="text-align: left;">
						<span>请上传营业执照清洗彩色原件扫描件或数码照在有效期内且年检章齐全(当年成立的可五年检章)由中国大陆工商局颁发
						支持jpg.jpeg.bmp.gif格式照片，大小不超过5M，只能上传一张图片</span>
          </td>
        </tr>
      </table>
      <el-divider content-position="center">公司运行情况</el-divider>
      <table class="myTable2" cellspacing="0px">
        <tr>
          <td>主要业务：</td>
          <td style="text-align: center;" colspan="4">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="运输"></el-checkbox>
              <el-checkbox label="仓储"></el-checkbox>
              <el-checkbox label="配送"></el-checkbox>
              <el-checkbox label="流通加工"></el-checkbox>
              <el-checkbox label="物流金融"></el-checkbox>
              <el-checkbox label="物流房产"></el-checkbox>
              <el-checkbox label="其他"></el-checkbox>
            </el-checkbox-group>
          </td>
        </tr>
        <tr>
          <td rowspan="5">项目展示：</td>
          <td colspan="4">
            <el-input v-model="form.projectShow1"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <el-input v-model="form.projectShow2"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <el-input v-model="form.projectShow3"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <el-input v-model="form.projectShow4"></el-input>
          </td>
        </tr>
        <tr>
          <td colspan="4">
            <el-input v-model="form.projectShow5"></el-input>
          </td>
        </tr>

        <tr>
          <td rowspan="2">经营状况：</td>
          <td>企业性质：</td>
          <td colspan="3">
            <el-select v-model="form.unitRegime">
              <el-option label="国有企业" :value="1"></el-option>
              <el-option label="外商独资企业" :value="2"></el-option>
              <el-option label="中外合资/合作企业" :value="3"></el-option>
              <el-option label="民营企业" :value="4"></el-option>
            </el-select>
          </td>
        </tr>


        <tr>
          <td>年营业收入：</td>
          <td>
            <el-select v-model="form.yearSales">
              <el-option label="300万及以下" :value="1"></el-option>
              <el-option label="300-1000万" :value="2"></el-option>
              <el-option label="1000-2000万" :value="3"></el-option>
              <el-option label="2000-5000万" :value="4"></el-option>
              <el-option label="5000万及以上" :value="5"></el-option>
            </el-select>
          </td>
          <td>企业开业时间：</td>
          <td>
            <el-select v-model="form.unitTime">
              <el-option label="1年以上" :value="1"></el-option>
              <el-option label="2年以上" :value="2"></el-option>
              <el-option label="3年以上" :value="3"></el-option>
            </el-select>

          </td>
        </tr>
        <tr>
          <td rowspan="2">企业资产：</td>
          <td>企业资产总额：</td>
          <td>
            <el-select v-model="form.totalAssets">
              <el-option label="1000万及以下" :value="1"></el-option>
              <el-option label="1000-2000万" :value="2"></el-option>
              <el-option label="2000-5000万" :value="3"></el-option>
              <el-option label="5000万-1亿" :value="4"></el-option>
              <el-option label="1亿及以上" :value="5"></el-option>
            </el-select>
          </td>
          <td>资产负债率：</td>
          <td>
            <el-select v-model="form.debtRatio">
              <el-option label="60%以下" :value="1"></el-option>
              <el-option label="60%以上" :value="2"></el-option>
            </el-select>
          </td>
        </tr>


        <tr>
          <td>年平均利润率：</td>
          <td>
            <el-input v-model="form.yearProfitRate"></el-input>
          </td>
          <td>KPI占比(年度)：</td>
          <td>
            <el-input v-model="form.kpiRate"></el-input>
          </td>
        </tr>


        <tr>
          <td rowspan="4">设备设施：</td>
          <td>可调用车辆：</td>
          <td colspan="3">
            <el-select v-model="form.vehicleCounts">
              <el-option label="10辆以上" :value="1"></el-option>
              <el-option label="30辆以上" :value="2"></el-option>
              <el-option label="50辆以上" :value="3"></el-option>
              <el-option label="100辆以上" :value="4"></el-option>
              <el-option label="200辆以上" :value="5"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>配送供应点：</td>
          <td>
            <el-select v-model="form.deliveryPoint">
              <el-option label="50以上" :value="1"></el-option>
              <el-option label="100以上" :value="2"></el-option>
              <el-option label="200以上" :value="3"></el-option>
              <el-option label="300以上" :value="4"></el-option>
              <el-option label="400以上" :value="5"></el-option>
            </el-select>
          </td>
          <td>自由仓储面积(㎡)：</td>
          <td>
            <el-select v-model="form.houseSpace">
              <el-option label="1000以上" :value="1"></el-option>
              <el-option label="3000以上" :value="2"></el-option>
              <el-option label="10000以上" :value="3"></el-option>
              <el-option label="50000以上" :value="4"></el-option>
              <el-option label="100000以上" :value="5"></el-option>
            </el-select>
          </td>
        </tr>

        <tr>
          <td>车辆平均利用率：</td>
          <td>
            <el-input v-model="form.vehicleUsageRate"></el-input>
          </td>
          <td>仓储平均利用率：</td>
          <td>
            <el-input v-model="form.houseUsageRate"></el-input>
          </td>
        </tr>
        <tr>
          <td>主要合作伙伴(两至三家)：</td>
          <td colspan="3">
            <el-input v-model="form.partnerItems"></el-input>
          </td>
        </tr>

        <tr>
          <td rowspan="4">管理及服务：</td>
          <td>管理制度：</td>
          <td colspan="3">
            <el-select v-model="form.isManage">
              <el-option label="有健全的经营、财务、统计、安全等机构和相应的管理制度" :value="1"></el-option>
              <el-option label="无制度" :value="0"></el-option>
            </el-select>
          </td>

        </tr>
        <tr>
          <td>质量管理：</td>
          <td colspan="3">
            <el-select v-model="form.isQuality">
              <el-option label="通过ISO9001-2000质量管理体系认证" :value="1"></el-option>
              <el-option label="无认证" :value="0"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>业务辐射范围：</td>
          <td>
            <el-select v-model="form.businessScope">
              <el-option label="本市" :value="1"></el-option>
              <el-option label="本省(直辖市)" :value="2"></el-option>
              <el-option label="本省及周边省区" :value="3"></el-option>
              <el-option label="全国" :value="4"></el-option>
              <el-option label="跨国境" :value="5"></el-option>
            </el-select>
          </td>
          <td>客户投诉率：</td>
          <td>
            <el-select v-model="form.complaintRate">
              <el-option label="小于0.05%" :value="1"></el-option>
              <el-option label="小于0.1%" :value="2"></el-option>
              <el-option label="小于0.5%" :value="3"></el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td>合同期限：</td>
          <td colspan="3">
            <el-select v-model="form.contractPeriod" placeholder="请选择">
              <el-option label="临时合同" :value="1"></el-option>
              <el-option label="半年合同" :value="2"></el-option>
              <el-option label="年度合同" :value="3"></el-option>
              <el-option label="长期合同(一年以上)" :value="4"></el-option>
            </el-select>
          </td>
        </tr>


        <tr>
          <td>信息化水平：</td>
          <td>电子单证管理：</td>
          <td>
            <el-select v-model="form.isInformation" placeholder="请选择">
              <el-option label="50%以上" :value="1"></el-option>
              <el-option label="70%以上" :value="2"></el-option>
              <el-option label="90%以上" :value="3"></el-option>
            </el-select>
          </td>
          <td>货物追踪：</td>
          <td>
            <el-select v-model="form.isTrack" placeholder="请选择">
              <el-option label="50%以上" :value="1"></el-option>
              <el-option label="70%以上" :value="2"></el-option>
              <el-option label="90%以上" :value="3"></el-option>
            </el-select>
          </td>
        </tr>
      </table>
      </el-form-item>
    </el-form>
    <div slot="footer" style="margin-top: 3%;margin-left: 25%;position: fixed;bottom: 35px;">
      <el-button @click="$router.push({name: 'MyCenter'})">取 消</el-button>
      <el-button type="primary" @click="formSubmit">保 存</el-button>
    </div>

    <div style="height: 100px;"></div>
  </div>
</template>
<script>
export default {
  name: 'userOther',
  data: function () {
    return {
      form: {
        id: '',
        userId: '',
        //资质情况
        unitName: '',
        unitEmail: '',
        unitAddress: '',
        dutyNumber: '',
        unitRegisterDate: '',
        unitScale: '',
        businessLicense: '',
        //资产情况
        serviceItems: '',
        projectShow1: '',
        projectShow2: '',
        projectShow3: '',
        projectShow4: '',
        projectShow5: '',
        unitRegime: '',
        yearSales: '',
        unitTime: '',
        totalAssets: '',
        debtRatio: '',
        yearProfitRate: '',
        kpiRate: '',
        deliveryPoint: '',
        houseSpace: '',
        vehicleUsageRate: '',
        houseUsageRate: '',
        partnerItems: '',
        isManage: '',
        isQuality: '',
        businessScope: '',
        complaintRate: '',
        contractPeriod: '',
        isInformation: '',
        isTrack: '',
      },
      checkList: [],
      fileList: [],
      dialogVisible: false,
      dialogImageUrl: '',
    }
  },
  mounted() {
    this.initform();
    this.initDetail();
  },
  methods: {
    initform: function () {
      if (this.$refs['form'] != undefined) {
        this.$refs['form'].resetFields();
      }
    },
    initDetail: function () {
      this.fileList = [],
          this.checkList = [];
      this.HTTP.get('/other/otherDetail').then(res => {
        if (res.data.code == 1) {
          this.form = res.data.data;
          if (res.data.data.fullPath) {
            this.fileList.push({url: res.data.data.fullPath});
          }
          if (res.data.data.serviceItems) {
            let arr = res.data.data.serviceItems.split(',');
            this.checkList = arr;
          }
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },

    handlePreview1: function (file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.HTTP.upload('/file/uploadImage', formData).then(res => {
        if (res.data.code == 1) {
          this.$message({message: '上传成功', type: 'success'});
          this.form.businessLicense = res.data.data
        } else {
          this.$message(res.data.message);
        }
      })
    },
    handleRemove1: function (file) {
      this.form.businessLicense = '';
    },
    handlePictureCardPreview: function (file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    cancel: function () {
      this.$emit('cancel', false);
    },
    formSubmit: function () {
      //获取复选框值 设置表单值
      let serviceItems = this.checkList;
      if (serviceItems != null) {
        this.form.serviceItems = serviceItems.toString();
      }
      //提交时判断id 是否存在 新增或修改
      this.HTTP.post('/other/formSubmit', this.form).then(res => {
        if (res.data.code == 1) {
          this.$message.success('保存成功');
          this.initform();
          this.initDetail();
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(err => {
        this.$message.error(err);
      })
    },
  }
};
</script>

<style scoped>
/**
 * 第一个table的样式
 */
.myTable {
  width: 100%;
  border: 1px solid #dcdfe6;
}

.myTable tr {
  height: 48px;
  border-bottom: 1px solid #dcdfe6;
}

.myTable tr td {
  width: 25%;
  border: 1px solid #dcdfe6;
  padding: 0 3px;
  text-align: right;
}

.myTable tr td :nth-child(odd) {
  text-align: center;
}

.myTable .el-select {
  width: 100%;
}

/**
 * 第二个table的样式
 */
.myTable2 {
  width: 100%;
  border: 1px solid #dcdfe6;
}

.myTable2 tr {
  height: 48px;
  border-bottom: 1px solid #dcdfe6;
}

.myTable2 tr td {
  width: 20%;
  border: 1px solid #dcdfe6;
  padding: 0 3px;
  text-align: right;
}

.myTable2 tr td :nth-child(odd) {
  text-align: center;
}

.myTable2 .el-select {
  width: 100%;
}
</style>

